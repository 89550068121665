<template>
  <section :class="`options-trackbar size-${size}`" :style="getStyle">
    <div
      v-for="(option, optionIndex) in options"
      :key="`option-trackbar-item-${optionIndex}`"
      :class="[
        `options-trackbar-item`,
        option[valueAttribute] === value ? 'selected' : '',
      ]"
      @click="onClick(option)"
    >
      <loading
        :active="true"
        :height="25"
        :opacity="0"
        :color="`#000`"
        :loader="`bars`"
        :is-full-page="false"
        :class="`outline-none`"
        v-if="
          clickedItemIsLoading &&
            lastClickedItemValue === option[valueAttribute]
        "
      />

      <template v-else>
        <div class="title">{{ option[titleAttribute] }}</div>
        <div class="subtitle">{{ option[subtitleAttribute] }}</div>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: 'OptionsTrackbar',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: undefined,
    },
    options: {
      type: [Array, Object],
      required: false,
      default: undefined,
    },
    titleAttribute: {
      type: String,
      required: true,
    },
    subtitleAttribute: {
      type: String,
      required: true,
    },
    valueAttribute: {
      type: String,
      required: true,
    },
    clickedItemIsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: 'base',
      validator(val) {
        return ['base', 'lg'].includes(val)
      },
    },
  },
  computed: {
    getStyle() {
      return `grid-template-columns: repeat(${this.options.length}, minmax(0, 1fr));`
    },
  },
  data() {
    return {
      onLoading: false,
      lastClickedItemValue: undefined,
    }
  },
  methods: {
    async onClick(option) {
      if (this.clickedItemIsLoading !== true) {
        this.lastClickedItemValue = option[this.valueAttribute]
        this.$emit('click', option)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.options-trackbar {
  --ot-bg-color: #ffffff;

  display: grid;
  // grid-template-columns: repeat(3, minmax(0, 1fr));
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 6px;
  border-radius: 4px;
  border: solid 1px #e7e7e7;
  background-color: var(--ot-bg-color);

  &.size-base {
    height: 58px;
  }
  &.size-lg {
    height: 110px;
  }

  & .options-trackbar-item {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    border-radius: 4px;
    margin-right: 4px;
    border: solid 1px var(--ot-bg-color);
    cursor: pointer;

    &:last-child {
      margin-right: 0 !important;
    }

    & .title {
      color: #434343;
      font-size: 14px;
      font-weight: 700;
    }
    & .subtitle {
      color: #707070;
      font-size: 11px;
      font-weight: 500;
    }

    &:hover,
    &.selected {
      border: solid 1px #1bca09;
      background-color: #f2fff1;
    }
  }
}
</style>
